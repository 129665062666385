var $ = jQuery.noConflict();

$(window).on('load', function () {
    let method = 'front';
    if ($('body').hasClass('wp-admin'))
        method = 'wp-admin';

    var initializeBlock = function ($block) {


        $('.block-half-divided-block .swiper').each(function () {
            if ($(this).find('.swiper-slide').length > 1) {
                var blockId = $(this).closest('.content-wrapper').attr('id');

                var swiperInit = new Swiper(this, {
                    slidesPerView: 1,
                    autoHeight: false,
                    spaceBetween: 20,
                    loop: false,
                    slideClass: 'swiper-slide',
                    navigation: {
                        prevEl: '#' + blockId + ' .btn-circle--prev',
                        nextEl: '#' + blockId + ' .btn-circle--next'
                    }
                });
            }
        });

        $('.block-half-divided-block .video-wrapper').click(function () {
            $(this).find('.modal').toggleClass('active');
            $('body').toggleClass('modal-opened');
        });
        $('.modal').click(function (e) {
            e.stopImmediatePropagation();
            $(this).toggleClass('active');
            $('body').toggleClass('modal-opened');
        });
        $('.modal-content').click(function (e) {
            e.stopImmediatePropagation();
        });

    };

    window.onload = function () {
        initializeBlock($(this));
    }();

    if (window.acf) {
        window.acf.addAction('render_block_preview/type=half-divided-block', initializeBlock);
    }
});
